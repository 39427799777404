(function() {
    'use strict';

    angular.module('ionicReportCard', [
        'ionic',
        'ionicAlert',
        'ionicArticle',
        'ionicArticleUserType',
        'ionicFeature',
        'ionicFitbit',
        'ionicHangout',
        'ionicLogin',
        'ionicNotification',
        'ionicPlaylist',
        'ionicSettings',
        'ionicUser',
        'ionicVideo',
        'uabDefaultVariable',
    ]);
})();
(function() {
    'use strict';

    angular.module('ionicReportCard').service(
        'ReportCardService',
        ReportCardService
    );

    ReportCardService.$inject = [
        'DefaultVariableService',
        'FeatureListService',
        'LoginService',
        'SettingsService',
        'UserService',
        'UserDurationService'
    ];

    function ReportCardService(
        DefaultVariableService,
        FeatureListService,
        LoginService,
        SettingsService,
        UserService,
        UserDurationService
    ) {
        var ReportCardService = this;

        ReportCardService.getCurrentWeekOptions = getCurrentWeekOptions;
        function getCurrentWeekOptions() {
            return UserService.getUser().then(
                function(user) {
                    if (user) {
                        return {
                            params: [
                                'week=' + UserDurationService.getWeeks(user)
                            ]
                        };
                    }

                    return false;
                }
            );
        }

        ReportCardService.getIsSocial = getIsSocial;
        function getIsSocial() {
            return SettingsService.getSetting('isSocial').then(
                function(isSocial) {
                    return DefaultVariableService.get(
                        isSocial,
                        false
                    );
                }
            );
        }

        ReportCardService.hasFeature = hasFeature;
        function hasFeature(key) {
            return FeatureListService.hasFeature(key);
        }

        ReportCardService.reset = reset;
        function reset() {

        }

        ReportCardService.init = init;
        function init() {
            ReportCardService.reset();
        }

        LoginService.register(ReportCardService);
    }
})();

(function() {
    'use strict';

    angular.module('ionicReportCard').controller(
        'ReportCardController',
        ReportCardController
    );

    ReportCardController.$inject = [
        'DefaultVariableService',
        'ReportCardService',
        'LoginService',
        '$scope'
    ];

    function ReportCardController(
        DefaultVariableService,
        ReportCardService,
        LoginService,
        $scope
    ) {
        var ReportCardController = this;

        $scope.$watch(
            'showArticles',
            function(showArticles) {
                ReportCardController.showArticles = false;

                if (DefaultVariableService.isDefined(showArticles)) {
                    ReportCardController.showArticles = showArticles;
                } else {
                    ReportCardService.hasFeature('articles').then(
                        function(showArticles) {
                            ReportCardController.showArticles = showArticles;
                        }
                    );
                }
            }
        );

        $scope.$watch(
            'showFitbitStats',
            function(showFitbitStats) {
                ReportCardController.showFitbitStats = false;

                if (DefaultVariableService.isDefined(showFitbitStats)) {
                    ReportCardController.showFitbitStats = showFitbitStats;
                } else {
                    ReportCardService.hasFeature('leaderboard').then(
                        function(showFitbitStats) {
                            ReportCardController.showFitbitStats = showFitbitStats;
                        }
                    );
                }
            }
        );

        $scope.$watch(
            'showHangouts',
            function(showHangouts) {
                ReportCardController.showHangouts = DefaultVariableService.get(
                    showHangouts,
                    true
                );
            }
        );

        $scope.$watch(
            'showInstructions',
            function(showInstructions) {
                ReportCardController.showInstructions = DefaultVariableService.get(
                    showInstructions,
                    true
                );
            }
        );

        $scope.$watch(
            'showNotifications',
            function(showNotifications) {
                ReportCardController.showNotifications = DefaultVariableService.get(
                    showNotifications,
                    true
                );
            }
        );

        $scope.$watch(
            'showPlaylists',
            function(showPlaylists) {
                ReportCardController.showPlaylists = false;

                if (DefaultVariableService.isDefined(showPlaylists)) {
                    ReportCardController.showPlaylists = showPlaylists;
                } else {
                    ReportCardService.hasFeature('playlists').then(
                        function(showPlaylists) {
                            ReportCardController.showPlaylists = showPlaylists;
                        }
                    );
                }
            }
        );

        $scope.$watch(
            'showVideos',
            function(showVideos) {
                ReportCardController.showVideos = false;

                if (DefaultVariableService.isDefined(showVideos)) {
                    ReportCardController.showVideos = showVideos;
                } else {
                    ReportCardService.hasFeature('videos').then(
                        function(showVideos) {
                            ReportCardController.showVideos = showVideos;
                        }
                    );
                }
            }
        );

        ReportCardController.clear = clear;
        function clear() {
            ReportCardController.reset();
        }

        ReportCardController.loadIsSocial = loadIsSocial;
        function loadIsSocial() {
            return ReportCardService.getIsSocial().then(
                function(isSocial) {
                    ReportCardController.isSocial = isSocial;

                    return isSocial;
                }
            );
        }

        ReportCardController.reset = reset;
        function reset() {
            ReportCardController.articleCurrentWeekOptions = false;

            ReportCardController.playlistCurrentWeekOptions = false;

            ReportCardController.videoCurrentWeekOptions = false;

            ReportCardService.getCurrentWeekOptions().then(
                function(currentWeekOptions) {
                    if (currentWeekOptions) {
                        ReportCardController.articleCurrentWeekOptions = angular.copy(
                            currentWeekOptions
                        );

                        ReportCardController.playlistCurrentWeekOptions = angular.copy(
                            currentWeekOptions
                        );

                        ReportCardController.videoCurrentWeekOptions = angular.copy(
                            currentWeekOptions
                        );
                    }
                }
            );

            ReportCardController.isSocial = false;
        }

        ReportCardController.init = init;
        function init() {
            ReportCardController.reset();
            ReportCardController.loadIsSocial();
        }

        LoginService.register(ReportCardController);
    }
})();
(function() {
    'use strict';

    angular.module('ionicReportCard').directive(
        'ionicReportCard',
        ionicReportCard
    );

    function ionicReportCard() {
        return {
            controller:   'ReportCardController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope:        {
                showArticles:      '=',
                showHangouts:      '=',
                showInstructions:  '=',
                showFitbitStats:   '=',
                showNotifications: '=',
                showPlaylists:     '=',
                showVideos:        '='
            },
            template:'<div class="list" data-ng-if="ctrl.articleCurrentWeekOptions && ctrl.playlistCurrentWeekOptions && ctrl.videoCurrentWeekOptions"><ionic-article-user-type-weekly data-ng-if="ctrl.showInstructions"></ionic-article-user-type-weekly><ionic-user-week-progress></ionic-user-week-progress><div data-ng-if="ctrl.showNotifications" class="card"><ionic-notification-list collapsible="true" only-unread="true" show-count="true" title="RECENT NOTIFICATIONS"></ionic-notification-list></div><div data-ng-if="ctrl.showArticles" class="card"><ionic-article-list collapsible="true" options="ctrl.articleCurrentWeekOptions" preview-char-limit="0" show-count="true" title="RECENT ARTICLES"></ionic-article-list></div><div data-ng-if="ctrl.showPlaylists" class="card"><ionic-playlist-list collapsible="true" options="ctrl.playlistCurrentWeekOptions" show-count="true" title="RECENT PLAYLISTS"></ionic-playlist-list></div><div data-ng-if="ctrl.showVideos" class="card"><ionic-video-list collapsible="true" options="ctrl.videoCurrentWeekOptions" title="RECENT VIDEOS"></ionic-video-list></div><div data-ng-if="ctrl.isSocial && ctrl.showHangouts" class="card"><ionic-hangout-list title="GROUP CONFERENCE CALLS"></ionic-hangout-list></div><ionic-fitbit-lifetime-stats data-ng-if="ctrl.isSocial && ctrl.showFitbitStats"></ionic-fitbit-lifetime-stats></div>'
        };
    }
})();